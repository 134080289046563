import { buildService } from '@rd-web-markets/shared/dist/services/service';
import serviceMethods from '@rd-web-markets/shared/dist/services/service_methods';
import { useCallback } from 'react';
function createTemplateService(endpoint) {
  const api = buildService(endpoint, {
    only: ['get', 'create']
  }, {
    only: ['postFormData']
  });
  const service = {
    useGet: _ref => {
      let {
        setLoading
      } = _ref;
      const [template, setTemplate, fetchTemplate] = serviceMethods.useFetch({
        callback: useCallback(async () => await api.get(), []),
        setLoading,
        initialStateValue: null
      });
      return [template, setTemplate];
    },
    useCreate(_ref2) {
      let {
        setLoading
      } = _ref2;
      const postFormData = serviceMethods.usePostFormData({
        callback: useCallback(async _ref3 => {
          let {
            formData
          } = _ref3;
          return await api.postFormData(formData);
        }, []),
        setLoading
      });
      return postFormData;
    }
  };
  return service;
}
export default createTemplateService;