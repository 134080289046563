import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import { buildService } from '@rd-web-markets/shared/dist/services/service';
import serviceMethods from '@rd-web-markets/shared/dist/services/service_methods';
import { useCallback } from 'react';
const api = buildService('/claim_groups/{id}/rnd_report_import_status', {
  only: ['all', 'base']
}, {
  only: ['postFormData']
});
const importRndReportApi = buildService('/claim_groups/{id}/rnd_report_imports', {
  only: ['all']
}, {
  only: ['postFormData']
});
const useFetchRndReportImportStatusWithInterval = _ref => {
  let {
    claimGroupId,
    setLoading
  } = _ref;
  const {
    interval,
    setInterval,
    stateVar: importStatus,
    setStateVar: setImportStatus
  } = serviceMethods.useFetchWithInterval({
    api,
    callback: useCallback(async () => await api.all(claimGroupId), [claimGroupId]),
    setLoading
  });
  return {
    importStatus,
    setImportStatus,
    interval,
    setInterval
  };
};
const useUploadRndReportDocxFile = _ref2 => {
  let {
    claimGroupId
  } = _ref2;
  //accepts an object with the formDataJson field and any other fields.
  const postFormData = serviceMethods.usePostFormData({
    callback: useCallback(async _ref3 => {
      let {
        formData
      } = _ref3;
      return await importRndReportApi.postFormData(claimGroupId, formData);
    }, [claimGroupId])
  });
  return postFormData;
};
const rndReportImportService = {
  useFetchRndReportImportStatusWithInterval,
  useUploadRndReportDocxFile
};
export default rndReportImportService;