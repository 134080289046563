import React, { useState, useRef, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as QueryString from 'query-string';
import ClaimList from '@components/shared/claim/ClaimList';
import SearchBar from '@components/util/SearchBar';
import { Breadcrumb, Card, Col, Pagination, Row } from 'react-bootstrap';
import claimService from '@services/claim/claim.service';
import { useDispatch } from 'react-redux';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import myClaimsService from '@services/claim/my_claims.service';
import MyClaimClaimGroupsNavigation from '@rd-web-markets/market/dist/my/MyClaimClaimGroupsNavigation';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import MasterSidebar from '@rd-web-markets/shared/dist/util/MasterSidebar';
import { useTranslation } from 'react-i18next';

let initialParams = 'page=1&query=';

const MyClaimsPage = ({accountType}) => {
  const [claims, setClaims] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const input = useRef(null);
  const [params, setParams] = useState(
    QueryString.parse(window.location.search || initialParams)
  );

  
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await myClaimsService.all(QueryString.stringify(params));
      setClaims(response.claims);
      setTotalPages(response.total_pages);
      history.push({
        path: 'admin/claims',
        search: QueryString.stringify(params),
      });
      setLoading(false);
    };
    fetchData();
  }, [history, params]);

  const handleSearch = (query) => {
    setParams({ ...params, query, page: 1 });
  };

  const handlePage = (pageNumber) => {
    setParams({ ...params, page: pageNumber });
  };

  const items = [];
  for (let number = 1; number <= totalPages; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === params.page}
        onClick={() => handlePage(number)}
      >
        {number}
      </Pagination.Item>
    );
  };

  const handleDeleteClaim = async (id) => {
    if(window.confirm('Are you sure you wish to delete this claim ?')){
      setLoading(true);
      try {
        await claimService.deleteClaim(id);
        setClaims(claims.filter((claim) => claim.id !== id));
      } catch (error) {
        dispatch(handleError(error));
      }
      setLoading(false);
    }
  };

  return <>
    <MasterSidebar accountType={accountType} currentPage='my_claim_groups' />
    <BreadcrumbsPortal>
      <Breadcrumb>
      <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${accountType}`}}>Home</Breadcrumb.Item>
      <Breadcrumb.Item active>{t('my_claims')}</Breadcrumb.Item>
      </Breadcrumb>
    </BreadcrumbsPortal>
    
    <Card>
      <Card.Header>
        <MyClaimClaimGroupsNavigation page='my_claims' accountType={accountType} />
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={12}>
            <Card>
              <Card.Header>{t('my_claims')}</Card.Header>
              <Card.Body>
                <Row>
                  <Col md={{ span: 4, offset: 8 }}>
                    <SearchBar onSubmit={handleSearch} loading={loading} query={params.query} />
                  </Col>
                </Row>
                {claims && <ClaimList role="admin" claims={claims} handleDeleteClaim={handleDeleteClaim} loading={loading}/>}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  </>;
}

export default MyClaimsPage
