import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import GeneralSection from '@components/shared/financialSection/general/GeneralSection';
import claimService from '@services/claim/claim.service';
import FinancialSectionBreadcrumb from '@components/shared/financialSection/FinancialSectionBreadcrumb';
import { Navigation, urlToSubpage } from '@rd-web-markets/market/dist/financialSection';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from '@rd-web-markets/shared/dist/util';
import { SIDEBAR_CLAIM_COSTS_LINKS, SIDEBAR_CLAIM_COSTS_LINKS_CUSTOMER } from '@rd-web-markets/market/dist/constants';
import SidebarPortal from '@rd-web-markets/shared/dist/util/SidebarPortal';
import UploadCostTemplatePage from './UploadCostTemplatePage';
import ClientCostEmailPage from './ClientCostEmailPage';
import MangerCostReviewPage from './ManagerCostReviewPage';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import { SIDEBAR_CLAIM_COSTS_LINKS_ACCOUNTANT } from '@rd-web-markets/market/dist/constants';
import { CompanyService } from '@rd-web-markets/shared/dist/services/company.service';
import claimProjectReportService from '@rd-web-markets/shared/dist/services/project_report/claim_project_report.service';
import claimProjectReportsListsService from '@rd-web-markets/shared/dist/services/lists/claim_groups/claim_project_reports_lists.service';
import { FINANCIAL_DATA_PAGE_NAME } from '@rd-web-markets/market/dist/constants';
import { useTranslation } from 'react-i18next';

const projectListParams = {
  page: 1,
  order_by: 'report_order',
  group_projects: false,
  query: ''
}

export default function FinancialDetailsPage({ accountType, handleToaster }) {
  const { page, claim_id, project_id } = useParams();
  const [claim, setClaim] = useState(null);
  const [claimGroupId, setClaimGroupId] = useState(null);
  const [_1, _2, claimGroup, setClaimGroup, resetCompanyAndClaimGroup] = CompanyService.useGetCompanyAndClaimGroup(claim?.company?.id, claimGroupId);
  const [allProjects, setAllProjects] = claimProjectReportsListsService.useFetchAll({ accountType, claimGroupId })
  const [project, setProject] = claimProjectReportService.useGet(claimGroupId, project_id)
  const dispatch = useDispatch();
  const [timer, setTimer] = useState(null);
  const user = useSelector((state) => state.auth.user);
  const { t } = useTranslation();

  const refreshClaim = useCallback(async () => {
    try {
      const claim = await claimService.get(claim_id);
      setClaimGroupId(claim.claim_group_id)
      setClaim(claim);
    } catch (error) {
      dispatch(handleError(error));
    }
  }, [dispatch, claim_id]);

  useEffect(() => {
    refreshClaim();
  }, [refreshClaim]);

  const saveClaim = useCallback(async (claim) => {
    try {
      await setClaim(await claimService.update(claim));
    } catch (e) {
      dispatch(handleError(e));
    }
  }, [dispatch]);

  const debouncedSaveClaim = useCallback(async () => {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }

    setTimer(
      setTimeout(async () => {
        try {
          await setClaim(await claimService.update(claim));
        } catch (error) {
          dispatch(handleError(error));
        }
      }, 2000)
    );
  }, [claim, dispatch, timer]);

  if (!claim) return <Loading />;

  const totalPayrollCostInvalid = claim.total_payroll_cost === null || 
    parseFloat(claim.total_payroll_cost) < 
      (claim.employee_net_amount ? 
        parseFloat(claim.employee_net_amount) : 
        parseFloat(claim.employee_wage_totals));

  const getCurrentPage = () => {
    switch (page) {
      case 'general':
        return (<>
          <h1 className='text-primary mb-4'>Cost Categories</h1>
          <Navigation page={page} claim={claim} accountType={accountType} project={project} />
          <GeneralSection project={project} claim={claim} accountType={accountType} setClaim={setClaim} saveClaim={saveClaim} />
        </>)
      case 'upload':
        return (<>
          <UploadCostTemplatePage
            claim={claim}
            loading={false}
            refreshClaim={refreshClaim}
            handleToaster={handleToaster}
          />
        </>)
      case 'email':
        return (<>
        <ClientCostEmailPage
          claim={claim}
          claimGroup={claimGroup}
          setClaimGroup={setClaimGroup}
          resetCompanyAndClaimGroup={resetCompanyAndClaimGroup}
          handleToaster={handleToaster}
        />
        </>)
      case 'manager_review':
        return (<>
          <MangerCostReviewPage
            claimId={claim_id}
            claimGroup={claimGroup}
            refreshClaim={refreshClaim}
            resetCompanyAndClaimGroup={resetCompanyAndClaimGroup}
          />
        </>)
      default:
        return (<>
          <h1 className='text-primary mb-4'>{t(FINANCIAL_DATA_PAGE_NAME)}</h1>
          <Navigation page={page} claim={claim} accountType={accountType} project={project} />
          {urlToSubpage(accountType, page, claim, refreshClaim, setClaim, debouncedSaveClaim, totalPayrollCostInvalid, saveClaim, project, claimGroup, setClaimGroup, allProjects)}
        </>)
    }
  }

  const getMenuItems = () => {
    let links;
    switch(user.account_type) {
      case 'customer':
        links = SIDEBAR_CLAIM_COSTS_LINKS_CUSTOMER;
        break;
      case 'accountant':
        links = SIDEBAR_CLAIM_COSTS_LINKS_ACCOUNTANT;
        break;
      default:
        links = SIDEBAR_CLAIM_COSTS_LINKS;
        break;
    }

    return links.map((item) => {
      const linkArray = item.link.split('/');
      return {
        link: `claims/${claim.id}/${item.link}`,
        icon: item.icon,
        text: item.text,
        highlighted: linkArray[0] !== 'edit' && linkArray[1] === page,
      };
    })
  }

  const backLink = () => {
    if(user.account_type === 'customer' || user.account_type === 'accountant') {
      return 'companies'
    }else {
      return `claim_groups/${claim.claim_group.id}/project_overview`;
    }
  }

  return <>
    <SidebarPortal 
      headerItem={{link: backLink(), text: claim.claim_group.name}}
      menuItems={getMenuItems()}
    />
    <FinancialSectionBreadcrumb claim={claim} page={page}/>
    {getCurrentPage()}
  </>
}
