import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as QueryString from 'query-string';
import SearchBar from '@components/util/SearchBar';
import { Breadcrumb, Col, Pagination, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';
import ClaimGroupList from '@components/shared/claim_group/list/ClaimGroupList';
import myClaimGroupsService from '@rd-web-markets/shared/dist/services/claim_groups/my_claim_groups.service';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import MasterSidebar from '@rd-web-markets/shared/dist/util/MasterSidebar';
import MyClaimClaimGroupsNavigation from '@rd-web-markets/market/dist/my/MyClaimClaimGroupsNavigation';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Loading } from '@rd-web-markets/shared/dist/util';

const initialParams = {
  page: 1,
  query: ''
}

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
       const key = keyGetter(item);
       const collection = map.get(key);
       if (!collection) {
           map.set(key, [item]);
       } else {
           collection.push(item);
       }
  });
  return map;
}

const MyClaimGroupsPage = ({accountType}) => {
  const user = useSelector((state) => state.auth.user);
  const [claimGroups, setClaimGroups] = useState(null);
  const [groupedByCompanyClaimGroups, setGroupedByCompanyClaimGroups] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [params, setParams] = useState(
    window.location.search ? QueryString.parse(window.location.search) : initialParams
  );

  const fetchData = async () => {
    setLoading(true);
    const response = await myClaimGroupsService.all(QueryString.stringify(params));
    setGroupedByCompanyClaimGroups(groupBy(response.claim_groups, cg => cg.company.name));
    setClaimGroups(response.claim_groups);
    setTotalPages(response.total_pages);
    history.push({
      path: 'admin/claim_groups',
      search: QueryString.stringify(params),
    });
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [history, params]);

  useEffect(() => {
    if(accountType === 'customer') {
      const contentWrapper = document.getElementById('content-wrapper');
      contentWrapper.style.left = "0"
    }

  }, [accountType])

  const handlePage = (pageNumber) => {
    setParams({ ...params, page: pageNumber });
  };

  const deleteClaimGroup = async claimGroupId => {
    if(window.confirm('Are you sure you wish to delete this claim group ?')){
      setLoading(true);
      try {
        await claimGroupService.delete(claimGroupId);
        await fetchData();
      } catch(error){
        dispatch(handleError(error));
      };
      setLoading(false);
    }
  };

  const items = [];
  for (let number = 1; number <= totalPages; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === params.page}
        onClick={() => handlePage(number)}
      >
        {number}
      </Pagination.Item>
    );
  };

  if(!claimGroups) return <Loading />;

  return (
    <>
      {accountType !== 'customer' &&
        <MasterSidebar accountType={accountType} currentPage='my_claim_groups' />
      }
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/${user.account_type}` }}>{t('home')}</Breadcrumb.Item>
          <Breadcrumb.Item active>{t('my_claim_groups')}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>

      <MyClaimClaimGroupsNavigation accountType={user.account_type} page='my_claim_groups' />
      <Row className='border-top border-light'>
        <Col md={12}>
          <h2 className="text-primary mb-4 mt-4 ">{t('my_claim_groups')}</h2>
          {claimGroups && <ClaimGroupList groupedByCompanyClaimGroups={groupedByCompanyClaimGroups} deleteClaimGroup={deleteClaimGroup} claimGroups={claimGroups} loading={loading} />}

        </Col>
      </Row>
    </>
  )
}

export default MyClaimGroupsPage
