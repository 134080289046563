import React, { useState, useEffect } from 'react'
import TemplateForm from '@components/shared/TemplateForm';
import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { Loading } from '@rd-web-markets/shared/dist/util';
import { useDispatch } from 'react-redux';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import { useHistory } from 'react-router-dom';
import kickoffEmailTEmplateService from '@services/kickoff_email_template.service';
import { useTranslation } from 'react-i18next';
import MasterSidebar from '@rd-web-markets/shared/dist/util/MasterSidebar';

const KickoffEmailTemplatePage = ({ handleToaster, accountType }) => {
  const [templates, setTemplates] = useState([]);
  const [createdTemplate, setCreatedTemplate] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const initialTemplate = { id: 0, title: t('create_new') }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await kickoffEmailTEmplateService.all();
        setTemplates([initialTemplate, ...response]);
      } catch (error) {
        dispatch(handleError(error));
      }
    };
    fetchData();
  }, [createdTemplate, dispatch]);

  const handleSubmit = async (template) => {
    setLoading(true);
    if(template.id === 0) {
      try {
        await kickoffEmailTEmplateService.create(template);
        handleToaster(t('template_created'));
        window.location.reload();
      } catch(error) {
        dispatch(handleError(error));
      }
    }
    else {
      try {
        await kickoffEmailTEmplateService.update(template.id, template);
        handleToaster(t('template_updated'));
        window.location.reload();
      } catch (error) {
        dispatch(handleError(error));
      }
    }
    setLoading(false);
  };

  const handleRemoveTemplate = async (template) => {
    setLoading(true);
    try {
      await kickoffEmailTEmplateService.delete(template.id);
      const templates = await kickoffEmailTEmplateService.all();
      setTemplates([initialTemplate, ...templates]);
    } catch(error) {
      dispatch(handleError(error));
    }
    setLoading(false);
  }

  const handleBack = () => {
    history.push('/admin');
  }

  if (!templates) return <Loading />

  return (
    <>
      <MasterSidebar accountType={accountType} currentPage='kickoff_email_template' />

      <TemplateForm
        handleSubmit={handleSubmit}
        templates={templates}
        templateType='email_template'
        createdTemplate={createdTemplate}
        loading={loading}
        handleBack={handleBack}
        handleRemoveTemplate={handleRemoveTemplate}
      />
    </> 
  );
}

export default KickoffEmailTemplatePage
