import fetch from '../Fetcher';
import { request } from '../request';
const claimProjectReportSliceService = {
  get: (project_report_id, slice_id) => {
    return fetch("/api/claim_project_reports/".concat(project_report_id, "/project_report_slices/").concat(slice_id), request.get);
  },
  update: (project_report_id, project_report_slice) => {
    return fetch("/api/claim_project_reports/".concat(project_report_id, "/project_report_slices/").concat(project_report_slice.id), {
      ...request.put,
      body: JSON.stringify({
        project_report_slice
      })
    });
  }
};
export default claimProjectReportSliceService;