import React, { useEffect, useState, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Breadcrumb, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import releaseNotesService from '@services/release_notes.service';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import { Table } from '@rd-web-markets/shared/dist/util';
import { DeleteButton, EditButton } from '@rd-web-markets/shared/dist/util/buttons';
import { ChevronDownButton } from '@rd-web-markets/shared/dist/util/buttons/ChevronDownButton';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import MasterSidebar from '@rd-web-markets/shared/dist/util/MasterSidebar';
import ReleasesModal from './ReleasesModal';

export default function ReleasesPage({ accountType }) {
  const history = useHistory();
  const { t } = useTranslation();
  const [releases, setReleases] = useState([]);
  const [modalMode, setModalMode] = useState('create');
  const [newRelease, setNewRelease] = useState({ id: null, release_number: '', release_date: null });
  const [modalShow, setModalShow] = useState(false);

  const refreshReleases = useErrorHandling(useCallback(async () => {
    const date = ['production', 'demo'].includes(ENVIRONMENT_MODE) ? new Date().toISOString().split('T')[0] : '';
    const releases = await releaseNotesService.getAll(`to_date=${date}`);
    setReleases(releases);
  }, []));

  useEffect(() => {
    refreshReleases();
  }, [refreshReleases]);
  
  const goToNotePage = (release) => {
    history.push(`/${accountType}/release_notes/${release.id}`);
  }

  const addRelease = () => {
    setModalMode('create');
    setNewRelease({ id: null, release_number: '', release_date: new Date() });
    setModalShow(true);
  }

  const changeRelease = async (release) => {
    setModalMode('update');
    setNewRelease(release);
    setModalShow(true);
  }

  const createRelease = useErrorHandling(useCallback(async (modalMode) => {
    if (modalMode === 'create') {
      await releaseNotesService.create(newRelease);
    } else {
      await releaseNotesService.update(newRelease.id, newRelease);
    }
    await refreshReleases();
  }, [newRelease, refreshReleases]));

  const deleteRelease = useErrorHandling(useCallback(async (release) => {
    if(window.confirm('Are you sure')) {
      await releaseNotesService.delete(release.id);
      await refreshReleases();
    }
  }, [refreshReleases]));

  const headers = [
    <th className='font-weight-normal col-5'>{t('release_number')}</th>,
    <th className='font-weight-normal col-5'>{t('release_date')}</th>,
  ];
  if (accountType === 'admin') headers.push(<th className='font-weight-normal col-2'>{t('actions')}</th>);

  const rows = releases.map((release) => {
    return {
      key: release.id,
      columns: [
        release.release_number,
        release.release_date,
      ],
      controls: accountType === 'admin' ? <>
        <ChevronDownButton onClick={() => goToNotePage(release)}/>
        <EditButton onClick={() => changeRelease(release)}/>
        <DeleteButton onClick={() => deleteRelease(release)}/>
      </> : <>
        <ChevronDownButton onClick={() => goToNotePage(release)}/>
      </>
    };
  });

  return <>
    <ReleasesModal
      newRelease={newRelease}
      setNewRelease={setNewRelease}
      modalShow={modalShow}
      setModalShow={setModalShow}
      createRelease={createRelease}
      modalMode={modalMode}
    />
    <MasterSidebar accountType={accountType} currentPage='' />
    <BreadcrumbsPortal>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${accountType}`}}>{t('home')}</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${accountType}/release_notes`}}>{t('releases')}</Breadcrumb.Item>
      </Breadcrumb>
    </BreadcrumbsPortal>
    {accountType === 'admin' && <Button variant="text" onClick={() => addRelease()}>
      <span className="material-icons md-18 blue">add</span>
      {t('add_release')}
    </Button>}
    

    <Table
      headers={headers}
      rows={rows}
      onEdit={changeRelease}
      onDelete={deleteRelease}
    />
  </>;
};
