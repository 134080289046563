import { api } from '@rd-web-markets/shared/dist/services/service';
import { useCallback } from 'react';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import { useDispatch } from 'react-redux';
const changeSetsService = {
  update: change_set => api.update("/change_sets/".concat(change_set.id), {
    change_set
  }),
  create: (objectId, objectType, review_type) => {
    console.log('create called with: ', objectId);
    const body = {
      change_set: {
        object_id: objectId,
        object_type: objectType,
        review_type: review_type
      }
    };
    return api.create('/change_sets', body);
  },
  createForClient: function (objectId, objectType, review_type) {
    let startReviewWithSendingEmail = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
    const body = {
      change_set: {
        send_email: startReviewWithSendingEmail,
        object_id: objectId,
        object_type: objectType,
        review_type: review_type
      }
    };
    return api.create('/change_sets', body);
  },
  useCreateChangeSet: (onChangeSetAction, setLoading) => {
    const dispatch = useDispatch();
    return async (object, object_type, review_type) => {
      setLoading && setLoading(true);
      try {
        await changeSetsService.create(object.id, object_type, review_type);
        onChangeSetAction();
      } catch (error) {
        dispatch(handleError(error));
      } finally {
        setLoading && setLoading(false);
      }
    };
  },
  useCreateChangeSetForClient: (onChangeSetAction, setLoading, handleToaster) => {
    const dispatch = useDispatch();
    return async (object, object_type, review_type, startReviewWithSendingEmail, setModalShow) => {
      setLoading && setLoading(true);
      try {
        await changeSetsService.createForClient(object.id, object_type, review_type, startReviewWithSendingEmail);
        setModalShow(false);
        startReviewWithSendingEmail && handleToaster('Email Sent!');
      } catch (error) {
        dispatch(handleError(error));
      } finally {
        onChangeSetAction();
        setLoading && setLoading(false);
      }
    };
  },
  useFinalizeChangeSet: (onChangeSetAction, setLoading) => {
    return useErrorHandling(useCallback(async object => {
      setLoading && setLoading(true);
      await changeSetsService.update({
        ...object.active_change_set,
        finalized_at: new Date().toISOString()
      });
      onChangeSetAction();
      setLoading && setLoading(false);
    }, [onChangeSetAction, setLoading]));
  },
  useApproveChangeSet: (onChangeSetAction, setLoading) => {
    const dispatch = useDispatch();
    return async object => {
      setLoading && setLoading(true);
      try {
        await changeSetsService.update({
          ...object.active_change_set,
          approved_at: new Date().toISOString()
        });
      } catch (error) {
        dispatch(handleError(error));
      } finally {
        onChangeSetAction();
        setLoading && setLoading(false);
      }
    };
  },
  useRejectChangeSet: (onChangeSetAction, setLoading) => {
    const dispatch = useDispatch();
    return async object => {
      setLoading && setLoading(true);
      try {
        await changeSetsService.update({
          ...object.active_change_set,
          rejected_at: new Date().toISOString()
        });
      } catch (error) {
        dispatch(handleError(error));
      } finally {
        onChangeSetAction();
        setLoading && setLoading(false);
      }
    };
  }
};
export default changeSetsService;