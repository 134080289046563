import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import * as QueryString from 'query-string';
import { Breadcrumb, Button, Card, Col, Pagination, Row } from 'react-bootstrap';
import SearchBar from '@components/util/SearchBar';
import { useDispatch } from 'react-redux';
import { CompanyService } from '@services/company.service';
import { Loading } from '@rd-web-markets/shared/dist/util';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import MasterSidebar from '@rd-web-markets/shared/dist/util/MasterSidebar';
import { useTranslation } from 'react-i18next';
import CompanyList from '@rd-web-markets/shared/dist/company/CompanyList';
import { CompanyCardActionButtons } from '@rd-web-markets/market/dist/company';
import { CompanyPageHeader } from '@rd-web-markets/market/dist/company';
import CompanyListForDocuments from '@rd-web-markets/shared/dist/company/CompanyListForDocuments';
import FolderAndFileManagement from '@rd-web-markets/shared/dist/folders_and_files/FolderAndFileManagement';
import { useFileManagementUrlHandler } from '@rd-web-markets/shared/dist/hooks/useFileManagementUrlHandler';
import SelectedModelFileManagement from '@rd-web-markets/shared/dist/folders_and_files/SelectedModelFileManagement';

const initialParams = 'page=1&query=';

const DocumentsPage = ({ accountType }) => {
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [params, setParams] = useState(QueryString.parse(location.search || initialParams));
  const modelId = new URLSearchParams(location.search).get('model_id');


  const queryString = QueryString.stringify(params);

  const [companies, totalPages, refreshCompanies] = CompanyService.useGetAll(queryString, setLoading);

  useEffect(() => {
    setLoading(true);
    history.push({
      path: `${accountType}/claims`,
      search: queryString,
    });
  }, [accountType, history, params]);

  const updateSearchParams = (query) => {
    setParams({ ...params, query, page: 1 });
  };

  const setPageNumerAndScrollToTop = (pageNumber) => {
    window.scrollTo(0, 0);
    setParams({ ...params, page: pageNumber });
  };

  const items = [];
  for (let number = 1; number <= totalPages; number++) {
    items.push(
      <Pagination.Item key={number} active={number === parseInt(params.page)} onClick={() => setPageNumerAndScrollToTop(number)}>
        {number}
      </Pagination.Item>
    );
  }

  if (!companies) return <Loading />;

  return (
    <>
      <MasterSidebar accountType={accountType} currentPage='documents' />
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/${accountType}` }}>
            {t('home')}
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{t('documents')}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>
      <Row>
        <Col md={12}>
          <Card>
            <Card.Header className='bg-gradient-primary text-white'>
              <CompanyPageHeader queryParams={params} setLoading={setLoading} loading={loading} />
            </Card.Header>
            <Card.Body>
              {(accountType === 'admin' || accountType === 'consultant' || accountType === 'super_consultant') && !modelId && (
                <Row>
                  <Col md={{ span: 4, offset: 4 }}>
                    <SearchBar onSubmit={updateSearchParams} loading={loading} query={params.query} />
                  </Col>
                </Row>
              )}
              {modelId ? (
                <SelectedModelFileManagement />
              ) : (
                <CompanyListForDocuments companies={companies} accountType={accountType}/>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {!modelId && (
        <Pagination className='justify-content-end'>{items}</Pagination>
      )}
    </>
  );
};

export default DocumentsPage;
