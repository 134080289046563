import fetch from '@rd-web-markets/shared/dist/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { handleError } from '../../store/features/alertSlice';
const rndReportService = {
  create(report_template_id, report_version, template_type, claimId) {
    let show_comments = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
    const claimIdQuery = template_type === 'aif' && claimId ? "&claim_id=".concat(claimId) : ''; //UK Specific
    const claim_report_template = {
      report_type: report_version,
      template_type,
      claimIdQuery,
      show_comments
    };
    return fetch("/api/report_templates/".concat(report_template_id, "/rnd_reports/"), {
      ...request.post,
      body: JSON.stringify(claim_report_template)
    });
  },
  preview: function (report_template_id, report_type, template_type, claimId) {
    let show_comments = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
    const templateTypeQuery = template_type ? "&template_type=".concat(template_type) : ''; //Italy Specific
    const claimIdQuery = template_type === 'aif' && claimId ? "&claim_id=".concat(claimId) : ''; //UK Specific

    return fetch("/api/report_templates/".concat(report_template_id, "/rnd_reports?report_type=").concat(report_type).concat(templateTypeQuery).concat(claimIdQuery, "&show_comments=").concat(show_comments), request.get);
  },
  delete(report_template_id, report_id) {
    return fetch("/api/report_templates/".concat(report_template_id, "/rnd_reports/").concat(report_id), request.delete);
  },
  all(report_template_id) {
    return fetch("/api/my/report_templates/".concat(report_template_id, "/rnd_reports/"), request.get);
  },
  usePreviewRndReportHighlightsVersion: _ref => {
    let {
      reportTemplateId,
      claimId,
      shouldShowComments
    } = _ref;
    const dispatch = useDispatch();
    const previewRndReportHighlightsVersion = useCallback(async (report_type, setReportHtmlWithHighlights, setReportPreviewGenerateTimestamp) => {
      try {
        const htmlResult = await rndReportService.preview(reportTemplateId, 'current', report_type, claimId, shouldShowComments);
        setReportHtmlWithHighlights(htmlResult.html);
        setReportPreviewGenerateTimestamp(Date.now());
      } catch (error) {
        console.error(error);
        dispatch(handleError(error));
      }
    }, [dispatch, reportTemplateId, claimId, shouldShowComments]);
    return previewRndReportHighlightsVersion;
  },
  usePreviewRndReportFinalVersion: _ref2 => {
    let {
      reportTemplateId,
      claimId
    } = _ref2;
    const dispatch = useDispatch();
    const previewRndReportFinalVersion = useCallback(async (report_type, setReportHtml) => {
      try {
        const htmlResult = await rndReportService.preview(reportTemplateId, 'final', report_type, claimId);
        setReportHtml(htmlResult.html);
      } catch (error) {
        console.error(error);
        dispatch(handleError(error));
      }
    }, [reportTemplateId, claimId, dispatch]);
    return previewRndReportFinalVersion;
  }
};
export default rndReportService;