import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import * as QueryString from 'query-string';
import { Breadcrumb, Button, Card, Col, Pagination, Row } from 'react-bootstrap';
import SearchBar from '@components/util/SearchBar';
import { useDispatch } from 'react-redux';
import { CompanyService } from '@services/company.service';
import { Loading } from '@rd-web-markets/shared/dist/util';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import MasterSidebar from '@rd-web-markets/shared/dist/util/MasterSidebar';
import { useTranslation } from 'react-i18next';
import CompanyList from '@rd-web-markets/shared/dist/company/CompanyList';
import { CompanyCardActionButtons, CompanyCardHeader } from '@rd-web-markets/market/dist/company';
import { CompanyPageHeader } from '@rd-web-markets/market/dist/company';
import { companyGroupService } from '@services/company_group.service';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';


const initialParams = 'page=1&query=';

const CompanyGroupPage = ({accountType}) => {
  const [companyGroups, setCompanyGroups] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const history = useHistory();
  const input = useRef(null);
  const [params, setParams] = useState(
    window.location.search ? QueryString.parse(window.location.search) : initialParams
  );

  const fetchCompanyGroups = useErrorHandling(useCallback(async () => {
    const response = await companyGroupService.all(QueryString.stringify(params));
    setCompanyGroups(response.company_groups);
    setTotalPages(response.pages);
    setLoading(false);
  }));

  useEffect(() => {
    setLoading(true);
    fetchCompanyGroups();
  }, [history, params]);


  const handleSearch = (query) => {
    setParams({ ...params, query, page: 1 });
  };

  const handlePage = (pageNumber) => {
    window.scrollTo(0, 0);
    setParams({ ...params, page: pageNumber });
  };

  const items = [];
  for (let number = 1; number <= totalPages; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === parseInt(params.page)}
        onClick={() => handlePage(number)}
      >
        {number}
      </Pagination.Item>
    );
  };


  const deleteCompanyGroup = async (id) => {
    if(window.confirm('Are you sure you wish to delete this claim ?')){
      setLoading(true);
      try {
        await companyGroupService.delete(id);
        setCompanyGroups(companyGroups.filter(cg => cg.id !== id));
      } catch(error) {
        dispatch(handleError(error));
      }
      setLoading(false);
    }
  }

  if(!companyGroups) return <Loading />;

  return (
    <>
      <MasterSidebar accountType={accountType} currentPage='companies' />
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${accountType}`}}>{t('home')}</Breadcrumb.Item>
          <Breadcrumb.Item active>{t('companies')}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>
      <Row>
        <Col md={12}>
          <Card>
          <Card.Header>
              <CompanyPageHeader queryParams={params} setLoading={setLoading} loading={loading} page={'company_groups_page'}/>
            </Card.Header>
            <Card.Body>
              { (accountType === 'admin' || accountType ==='consultant' || accountType ==='super_consultant') && 
              <Row>
                <Col md={4}>
                  <Button
                    variant="primary"
                    as={Link}
                    to={`/${accountType}/company_groups/create`}>
                    <span className="material-icons md-18">add</span> {t('add_company_group')}
                  </Button>
                </Col>
                <Col md={{ span: 4, offset: 4 }}>
                  <SearchBar onSubmit={handleSearch} loading={loading} query={params.query} />
                </Col>
              </Row> }
              {companyGroups && (
                <CompanyList
                  type={'company_group'}
                  deleteCompanyGroup={deleteCompanyGroup}
                  models={companyGroups}
                  accountType={accountType}
                  CompanyCardActionButtons={CompanyCardActionButtons}
                  setLoading={setLoading}
                  refreshCompanies={fetchCompanyGroups}
                  CompanyCardHeader={CompanyCardHeader}
                />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Pagination className="justify-content-end">{items}</Pagination>
    </>
  );
}

export default CompanyGroupPage
