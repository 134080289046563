import React from 'react'
import { Button, Card, Row, Col, Breadcrumb } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Table, Loading } from '@rd-web-markets/shared/dist/util';
import { DeleteButton, EditButton } from '@rd-web-markets/shared/dist/util/buttons';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import questionnairesService from '@services/questionnaires.service';
import MasterSidebar from '@rd-web-markets/shared/dist/util/MasterSidebar';

const QuestionnairesPage = ({ accountType }) => {
  const user = useSelector((state) => state.auth.user);
  const { t } = useTranslation();

  const [questionnaires, setQuestionnaires] = questionnairesService.useQuestionnaires();

  const deleteQuestionnaire = questionnairesService.useDeleteQuestionnaire(setQuestionnaires);

  const headers = [
    { text: t('date') },
    { text: t('name') },
    { text: t('#_of_questions') },
    { text: t('used') },
    '',
  ];

  if (!questionnaires) return <Loading />

  const rows = questionnaires.map(questionnaire => {
    return {
      key: questionnaire.id,
      columns: [
        questionnaire.created_at.slice(0, 10),
        questionnaire.name,
        questionnaire.questions?.length,
        <div>TODO</div>,
        <>
          <EditButton
            as={Link}
            to={`/${user.account_type}/questionnaires/${questionnaire.id}/edit/`}
            data-testid={`edit-button-${questionnaire.id}`}
          />
          <DeleteButton
            onClick={() => deleteQuestionnaire(questionnaire, questionnaires)}
            data-testid={`delete-button-${questionnaire.id}`}
          />
        </>,
      ]
    }
  });

  return (
    <>
      <MasterSidebar accountType={accountType} currentPage='questionnaires' />
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}`}}>{t('home')}</Breadcrumb.Item>
          <Breadcrumb.Item active>{t('questionnaires')}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal> 
      <Card>
        <Row>
          <Col md={12} className='text-center p-4'>
            <Button
              className='text-center'
              data-testid='add-questionnaire-button'
              variant="light"
              size="md"
              as={Link}
              to={`/${user.account_type}/questionnaires/create`}
            >
              <span className="material-icons md-18">add</span>{t('questionnaire')}
            </Button>
          </Col>
        </Row>
      </Card>
      <Card data-testid='questionnaires-table'>
        <Table headers={headers} rows={rows}/>
      </Card>
    </>
  )
}

export default QuestionnairesPage;