import { createSlice } from '@reduxjs/toolkit';
export const claimProjectReports = createSlice({
  name: 'claimProjectReports',
  initialState: {
    claimProjectReports: []
  },
  reducers: {
    storeMultipleClaimProjectReports(state, _ref) {
      let {
        payload: claimProjectReportsArray
      } = _ref;
      console.log('storeMultipleClaimProjectReports: ', claimProjectReportsArray);
      state.claimProjectReports = claimProjectReportsArray;
    },
    storeClaimProjectReport(state, _ref2) {
      let {
        payload: claimProjectReport
      } = _ref2;
      const reportIndex = state.claimProjectReports.findIndex(p => p.id === claimProjectReport.id);
      if (reportIndex >= 0) {
        console.log('storeClaimProjectReport 1: ');
        state.claimProjectReports.splice(reportIndex, 1, claimProjectReport);
      } else {
        console.log('storeClaimProjectReport 2: ');
        state.claimProjectReports = [...state.claimProjectReports, claimProjectReport];
      }
    }
  }
});
export const {
  storeClaimProjectReport,
  storeMultipleClaimProjectReports
} = claimProjectReports.actions;
export default claimProjectReports.reducer;