import React from 'react'
import { useParams } from 'react-router-dom';
import RegularRealtimeDashboard from '@rd-web-markets/market/dist/dashboards/RegularRealtimeDashboard';
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';
import ClaimGroupSidebar from '@rd-web-markets/shared/dist/pages/setup/ClaimGroupSidebar';
import { Loader } from '@rd-web-markets/shared/dist/util/Loader';

const ClientRegularRealtimeDashboardPage = ({ accountType }) => {
  const { claim_group_id } = useParams();
  const [claimGroup] = claimGroupService.useGetClaimGroup(claim_group_id)

  if (!claimGroup) return <Loader />

  return (
    <>
      <ClaimGroupSidebar
        claimGroup={claimGroup}
        accountType={accountType}
        highlightedMenuItem='dashboard'
        company={claimGroup.company}
        claims={claimGroup.claims}
      />

      <RegularRealtimeDashboard />
    </>
  );
}

export default ClientRegularRealtimeDashboardPage;
