import useConsultantRole from '@rd-web-markets/shared/dist/hooks/useConsultantRole';
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';
import { CancelButton, SaveButton } from '@rd-web-markets/shared/dist/util/buttons';
import { t } from 'i18next';
import { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import claimProjectReportService from '@rd-web-markets/shared/dist/services/project_report/claim_project_report.service';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import getClaimGroupsService from '@rd-web-markets/shared/dist/services/claim_groups/getClaimGroups.service';
import claimProjectReportsListsService from '@rd-web-markets/shared/dist/services/lists/claim_groups/claim_project_reports_groups_list.service';
import { useDispatch } from 'react-redux';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';

const ClaimProjectReportForm = ({accountType, companies, loading, Link, handleToaster, setLoading}) => {
  const [ getConsultantRole ] = useConsultantRole();
  const history = useHistory();
  const dispatch = useDispatch();
  
  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [claimGroups, setClaimGroups] = useState([]);
  const [projectReports, setProjectReports] = useState([]);
  const [users, setUsers] = useState([]);

  const [company, setCompany] = useState({});
  const [claimGroupId, setClaimGroupId] = useState();
  const [claimProjectReport, setProjectReport] = useState();
  const [user, setUser] = useState();
  const [claimGroup, setClaimGroup] = useState(null);

  //const [claimGroup] = claimGroupService.useGetClaimGroup(claimGroupId);

  useEffect(() => {
    setCompaniesOptions(companies?.map((c) => ({ value: c.id, label: `${c.name}`})));
  }, [companies]);

  useEffect(() => {
    const getClaimGroups = async () => {
      if(company.id) {
        try {
          setLoading(true);
          const claimGroups = await getClaimGroupsService.all(company.id);
          setClaimGroups(claimGroups);
        } catch (e) {
          dispatch(handleError(e));
        } finally {
          setLoading(false);
        }

      }
    }
    getClaimGroups();
  }, [company]);

  useEffect(() => {
    const getClaimProjectReports = async () => {
      if (claimGroup?.id) {
        try {
          const reports = await claimProjectReportsListsService.all(claimGroup.id);
          console.log('Reports are', reports);
          const excluded_from_time_tracking_reports = reports.claim_project_reports.filter((p) => p.included_in_time_tracking == false);
          setProjectReports(
            excluded_from_time_tracking_reports?.sort((a, b) => a.report_order - b.report_order)
          );
          setUsers(claimGroup?.consultant_roles);
        } catch(e) {
          dispatch(handleError(e))
        } finally {
          setLoading(false);
        }
      }
    }
    getClaimProjectReports();

  }, [claimGroup]);

  const updateClaimProjectFn = claimProjectReportService.useUpdateClaimProjectReport(claimGroupId, claimProjectReport);

  function findAndSetCompany(e) {
    setCompany(companies.find((c) => c.id == e.value));
  };

  function findAndSetClaimGroup(e) {
    setClaimGroup(claimGroups.find((claimGroup) => claimGroup.id == e.target.value));
    setClaimGroupId(claimGroups.find((claimGroup) => claimGroup.id == e.target.value)?.id);
  };

  function findAndSetProjectReport(e) {
    setProjectReport(projectReports.find((p) => p.id == e.target.value));
  };

  function findAndSetUser(e) {
    setUser(users.find((u) => u.id == e.target.value));
  };

  const success = () => {
    handleToaster('Project has created in Task Management');
    history.push(`/${accountType}/list_of_companies`);
  }

  const submit = (e) => {
    e.preventDefault();

    const params = {
      included_in_time_tracking: true,
      user_ids: [
        user.id
      ]
    }
    updateClaimProjectFn(params, success);
  };

  


  return(
    <Form onSubmit={submit}>
      <Row>
        <Col md={12}>
          <Form.Group as={Row}>
            <Form.Label column='md' md={3}>
              {`${t('company')} *`}
            </Form.Label>
            <Col md={{ span: 5, offset: 4 }}>
              <Select
                instanceId='company-select'
                value={companiesOptions?.find((c) => { c.value == company?.id })}
                onChange={findAndSetCompany}
                options={companiesOptions}
                required='true'
                styles={{
                  control: (baseStyles) => ({...baseStyles, borderColor: '#80bbdc' })
                }}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column="md" md={3}>
              {`${t('claim_group')} *`}
            </Form.Label>
            <Col md={{ span: 5, offset: 4 }}>
              <Form.Control 
                as="select"
                data-testid='claim-group-select'
                required='true'
                value={claimGroupId}
                onChange={(e) => findAndSetClaimGroup(e)}
                >
                  <option></option>
                  {claimGroups?.map((c) => <option data-testid='claim-group-option' key={c.id} value={c.id}>{c.name}</option>)}
                </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column="md" md={3}>
              {`${t('project')} *`}
            </Form.Label>
            <Col md={{ span: 5, offset: 4 }}>
              <Form.Control 
                as="select"
                data-testid='project-select'
                value={claimProjectReport?.id}
                required='true'
                onChange={(e) => findAndSetProjectReport(e)}
                >
                  <option></option>
                  {projectReports?.map((p) => <option data-testid='claim-project-report-option' key={p.id} value={p.id}>{p.project_name}</option>)}
                </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column="md" md={3}>
              {`${t('assignee')} *`}
            </Form.Label>
            <Col md={{ span: 5, offset: 4 }}>
              <Form.Control 
                as="select"
                data-testid='user-select'
                required='true'
                value={user?.id}
                onChange={(e) => findAndSetUser(e)}
                >
                  <option></option>
                  {users?.map((u) => <option data-testid='claim-group-user-option' key={u.id} value={u.id}>{`${u.name} ${getConsultantRole(u.role)}`}</option>)}
                </Form.Control>
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className="text-right">
          <CancelButton
            as={Link}
            to={`/${accountType}/list_of_companies`}
            className="mr-2"
          />
          <SaveButton loading={loading} text={t('save')}/>
        </Col>
      </Row>
    </Form>
  )

}

export default ClaimProjectReportForm;
