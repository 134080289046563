import React from 'react'
import { useParams, Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Card, Form, Row, Col, Breadcrumb, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import MasterSidebar from '@rd-web-markets/shared/dist/util/MasterSidebar';
import { SaveButton, CancelButton, AddButton } from '@rd-web-markets/shared/dist/util/buttons';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import questionnairesService from '@services/questionnaires.service';
import Question from './Question';

const QuestionnaireFormPage = ({ accountType }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const user = useSelector((state) => state.auth.user);

  const [questionnaire, setQuestionnaire] = questionnairesService.useQuestionnaire(id);

  const saveQuestionnaire = questionnairesService.useSaveQuestionnaire(questionnaire);

  const setQuestionField = (index, value, field) => {
    questionnaire.questions[index][field] = value;
    setQuestionnaire({ ...questionnaire, questions: questionnaire.questions });
  }

  const addQuestion = () => {
    questionnaire.questions.push({ question_type: 'open_question', body: '', description: '' });
    setQuestionnaire({ ...questionnaire, questions: questionnaire.questions });
  }

  const removeQuestion = (index) => {
    questionnaire.questions[index]._destroy = true;
    setQuestionnaire({ ...questionnaire, questions: questionnaire.questions });
  }

  return (
    <>
      <MasterSidebar accountType={accountType} currentPage='questionnaires' />
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}`}}>{t('home')}</Breadcrumb.Item>
          <Breadcrumb.Item active>{t(questionnaire.id ? 'edit_questionnaire' : 'add_questionnaire')}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>
      <Card>
        <Form onSubmit={saveQuestionnaire}>
          <Card.Body>
            <Form.Group as={Row}>
              <Form.Label className='col-form-label-lg' column>
                {t(questionnaire.id ? 'edit_questionnaire' : 'create_questionnaire')}
              </Form.Label>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column='md' md={2}>
                {t('name')}
              </Form.Label>
              <Form.Group as={Col} md={8}>
                <Form.Control
                  as='input'
                  data-testid='set-questionnaire-name'
                  onChange={(event) => setQuestionnaire({ ...questionnaire, name: event.target.value })}
                  size='md'
                  type='text'
                  value={questionnaire.name}
                  placeholder={t('enter_questionnaire_name')}
                />
              </Form.Group>
            </Form.Group>
            <Dropdown.Divider />
            {questionnaire.questions.filter(q => !q._destroy).map((question, index) => {
              return (
                <Question
                  key={index}
                  index={index}
                  question={question}
                  questionnaire={questionnaire}
                  setQuestionField={setQuestionField}
                  removeQuestion={removeQuestion}
                />
              );
            })}
            <Form.Group as={Row} className='mb-0 ml-0'>
              <AddButton
                variant='info'
                onClick={addQuestion}
                text={t('add_question')}
                data-testid='add-button'
              />
            </Form.Group>
            <Form.Group as={Row} className='col-md-10 ml-0' style={{justifyContent: 'flex-end'}}>
              <SaveButton className='mr-2' data-testid='save-button'/>
              <CancelButton onClick={history.goBack} data-testid='cancel-button'/>
            </Form.Group>
          </Card.Body>
        </Form>
      </Card>
    </>
  )
}

export default QuestionnaireFormPage;
