import { buildService } from '../service';
import serviceMethods from '../service_methods';
import { useCallback } from 'react';
const api = buildService('/claim_project_reports/{id}/project_rnd_reports', {
  only: ['all', 'create', 'update', 'delete', 'base']
});
const useFetchAll = _ref => {
  let {
    projectId,
    setLoading
  } = _ref;
  const [projectRndReports, setProjectRndReports, fetchProjectRndReports] = serviceMethods.useFetch({
    callback: useCallback(async queryParams => await api.all(projectId, queryParams), [projectId]),
    setLoading
  });
  return [projectRndReports, setProjectRndReports, fetchProjectRndReports];
};
const useDelete = _ref2 => {
  let {
    projectId,
    setLoading,
    onSuccess
  } = _ref2;
  const deleteModel = serviceMethods.useDelete({
    onSuccess: onSuccess,
    callback: useCallback(async projectRndReportId => await api.delete(projectId, projectRndReportId), [projectId]),
    setLoading
  });
  return deleteModel;
};
const useCreate = _ref3 => {
  let {
    projectId,
    setLoading,
    onSuccess
  } = _ref3;
  const createModel = serviceMethods.useCreate({
    onSuccess: onSuccess,
    callback: useCallback(async _ref4 => {
      let {
        report_type,
        show_comments
      } = _ref4;
      console.log('in callback');
      await api.create(projectId, {
        report_type,
        show_comments
      });
    }, [projectId]),
    setLoading
  });
  return createModel;
};

// const usePreview = ({ projectId, setLoading }) => {
//   const [object, setObject, fetchPreviewHtml] = serviceMethods.useFetch({
//     callback: useCallback(async (report_type, setReportHtmlWithHighlights, setReportPreviewGenerateTimestamp) => {
//       const res = await api.all(projectId, { report_type })

//     }, [projectId]),
//     setLoading,
//     initialQueryParams: { report_type: 'final' }
//   })

//   return fetchPreviewHtml
// }

const usePreviewProjectRndReportHighlightsVersion = _ref5 => {
  let {
    projectId,
    setLoading,
    shouldShowComments
  } = _ref5;
  const previewHighlightsVersion = serviceMethods.useHttpCallback({
    callback: useCallback(async (report_type, setReportHtmlWithHighlights, setReportPreviewGenerateTimestamp) => {
      const htmlResult = await api.all(projectId, {
        report_type: 'current',
        show_comments: shouldShowComments
      });
      setReportHtmlWithHighlights(htmlResult.html);
      setReportPreviewGenerateTimestamp(Date.now());
    }, [projectId, shouldShowComments]),
    setLoading
  });
  return previewHighlightsVersion;
};
const usePreviewProjectRndReportFinalVersion = _ref6 => {
  let {
    projectId,
    setLoading
  } = _ref6;
  const previewFinalVersion = serviceMethods.useHttpCallback({
    callback: useCallback(async (report_type, setReportHtml) => {
      const htmlResult = await api.all(projectId, {
        report_type: 'final'
      });
      setReportHtml(htmlResult.html);
    }, [projectId]),
    setLoading
  });
  return previewFinalVersion;
};
const projectRndReportService = {
  // useCrud: ({ projectId, setLoading, onCreateSuccess, onDeleteSuccess }) => ({
  //   // fetchAll: useFetchAll({ projectId, setLoading }),
  //   // delete: useDelete({ projectId, setLoading, onSuccess: onDeleteSuccess }),
  //   // create: useCreate({ projectId, setLoading, onSuccess: onCreateSuccess }),
  //   // preview: usePreview({ projectId, setLoading })
  // }),
  useCreate: useCreate,
  useDelete: useDelete,
  usePreviewProjectRndReportHighlightsVersion,
  usePreviewProjectRndReportFinalVersion
};
export default projectRndReportService;