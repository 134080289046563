import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import changeSetsService from '@rd-web-markets/shared/dist/services/change_sets.service';
import claimChangeSetsService from '@services/claim_groups/claim_change_sets_service';
import CostManagerReviewControls from 'src/components/shared/managerReviews/CostManagerReviewControls';
import ObjectChangeSetsList from 'src/components/shared/managerReviews/ObjectChangeSetsList'
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import image from '../../../assets/images/no-results.svg';

const MangerCostReviewPage = ({
  claimId,
  claimGroup,
  resetCompanyAndClaimGroup,
  refreshClaim,
}) => {
  const [loading, setLoading] = useState(false);
  const [claim, setClaim] = useState(null);

  useEffect(() => {
    if (claimGroup) {
      const currentClaim = claimGroup.claims.find(c => c.id === Number(claimId)) || claimGroup.claims[0];
      setClaim(currentClaim)
    }
  }, [claimId, claimGroup]);

  useEffect(() => {
    resetCompanyAndClaimGroup()
  }, [resetCompanyAndClaimGroup])

  const createChangeSetsForAllClaimsInClaimGroup = useErrorHandling(async () => {
    setLoading(true);
    await claimChangeSetsService.create(claimGroup.id)
    await resetCompanyAndClaimGroup();
    await refreshClaim();
    setLoading(false);
  })

  const createChangeSet = useErrorHandling(async (object, object_type, review_type) => {
    setLoading(true);
    await changeSetsService.create(object.id, object_type, review_type);
    await resetCompanyAndClaimGroup();
    setLoading(false);
  })

  const finalizeChangeSet = useErrorHandling(async (object) => {
    setLoading(true);
    await changeSetsService.update(
      { ...object.active_change_set, finalized_at: new Date().toISOString() }
    );
    await resetCompanyAndClaimGroup();
    setLoading(false);
  });

  const approveChangeSet = useErrorHandling(async (object) => {
    setLoading(true);
    await changeSetsService.update({
      ...object.active_change_set,
      approved_at: new Date().toISOString(),
    });
    await resetCompanyAndClaimGroup();
    await refreshClaim();
    setLoading(false);
  });

  const rejectChangeSet = useErrorHandling(async (object) => {
    setLoading(true);
    await changeSetsService.update({
      ...object.active_change_set,
      rejected_at: new Date().toISOString(),
    });
    await resetCompanyAndClaimGroup();
    await refreshClaim();
    setLoading(false);
  });

  return (<>
    <h1 className='text-primary mb-4'>Manager Cost Review</h1>

    <Row className='justify-content-center'>
        <img
          src={image}
          alt='Manager Cost Review'
          className='img-fluid'
          style={{ }}
        />     
    </Row>
    <Row>
          {claim && <Col md={{ span: 10, offset: 1 }}>
            <CostManagerReviewControls
                reviewedObject={claim}
                reviewedObjectClass='Claim'
                loading={loading}
                claimGroup={claimGroup}
                createChangeSet={createChangeSet}
                approveChangeSet={approveChangeSet}
                rejectChangeSet={rejectChangeSet}
                finalizeChangeSet={finalizeChangeSet}
                createChangeSetsForAllClaimsInClaimGroup={createChangeSetsForAllClaimsInClaimGroup}
              />
              <ObjectChangeSetsList object={claim} />
          </Col>}
        </Row>
  </>)
};

export default MangerCostReviewPage;