import { CLAIM_GROUP_CONSULTANT_ROLE_NAMES } from '../constants';
export function getLeadConsultant(claimGroup) {
  const consultants = claimGroup.consultants || claimGroup.consultants_attributes || claimGroup.consultant_roles;
  const leadConsultant = consultants.find(consultant => consultant.role === 'claim_group_access_lead_consultant');
  return leadConsultant || null;
}
export function getSupportingConsultants(claimGroup) {
  const consultants = claimGroup.consultants || claimGroup.consultants_attributes || claimGroup.consultant_roles;
  const supportingConsultants = consultants.filter(consultant => consultant.role === 'claim_group_access_suporting_consultant');
  return supportingConsultants;
}
export function isUserClaimGroupConsultant(user, claimGroupOrChildObject) {
  var _user$roles;
  // In case claim_group_id exists then its a child object of the claim group.
  // In case it doesnt and there is a role on the object's id - then it is the claim group.
  const roleResourceId = claimGroupOrChildObject.claim_group_id || claimGroupOrChildObject.id;
  const resourceRoles = (_user$roles = user.roles) === null || _user$roles === void 0 ? void 0 : _user$roles.filter(role => role.resource_id === roleResourceId);

  // the object wouldnt be a non claim group if it has claim group consultant role on itself
  const hasConsultantRole = resourceRoles.some(role => CLAIM_GROUP_CONSULTANT_ROLE_NAMES.includes(role.name));
  return hasConsultantRole;
}
export function isUserClaimGroupManager(user, claimGroup) {
  return user.id === (claimGroup === null || claimGroup === void 0 ? void 0 : claimGroup.admin.id);
}
export function isUserAdmin(user) {
  return user.account_type === 'admin' || user.account_type === 'super_consultant';
}