import React, { useState } from 'react'
import { Card } from 'react-bootstrap'
import ObjectChangeSetsList from './ObjectChangeSetsList'
import ButtonControls from '@rd-web-markets/shared/dist/technical_review/ButtonControls'
import { useTranslation } from 'react-i18next'
import ObjectChangeSetsListWithSnapshots from '@rd-web-markets/shared/dist/technical_review/ObjectChangeSetsListWithSnapshots'

const ClaimGroupTechnicalManagerQAReviews = ({
    claimGroup,
    loading,
    company,
    createChangeSet,
    approveChangeSet,
    rejectChangeSet,
    finalizeChangeSet,
    resetCompanyAndClaimGroup
  }) => {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
  return (
    <>
      <h2 className="text-primary mb-4">{t('manager_review')}</h2>
      <Card className='shadow border-0'>
        <Card.Body>
          {claimGroup &&
            <>
              <ButtonControls
                reviewedObject={claimGroup}
                reviewedObjectClass={'ClaimGroup'}
                loading={loading}
                company={company}
                createChangeSet={createChangeSet}
                finalizeChangeSet={finalizeChangeSet}
                approveChangeSet={approveChangeSet}
                rejectChangeSet={rejectChangeSet}
                buttonName={t('set_claim_ready_for_manager_review')}
              />
            <ObjectChangeSetsListWithSnapshots object={claimGroup} reviewType={'consultant_review'} resetData={resetCompanyAndClaimGroup}/>
          </>
        }

        {/* If by any chance */}
        { !claimGroup && <span>Please create a Claim Group</span> }
        </Card.Body>
      </Card>
    </>
  );
}

export default ClaimGroupTechnicalManagerQAReviews
