import React, { useState, useEffect, useCallback } from 'react'
import { Button, Card, Breadcrumb, Badge } from 'react-bootstrap'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import * as QueryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { Loading } from '@rd-web-markets/shared/dist/util';
import { Table } from '@rd-web-markets/shared/dist/util';
import { useSelector } from 'react-redux';
import { claimProjectReportsService } from '@services/task_management/claimProjectReports.service';
import claimProjectReportService from '@services/claim_project_report.service';
import { DeleteButton, EditButton } from '@rd-web-markets/shared/dist/util/buttons';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import Filters from '@rd-web-markets/shared/dist/util/Filters';
import MasterSidebar from '@rd-web-markets/shared/dist/util/MasterSidebar';
import Pagination from '@rd-web-markets/shared/dist/util/Pagination';

const initialParams = 'page=1&query=';

const TimeTrackingProjectsPage = ({ handleToaster, accountType }) => {
  const location = useLocation();
  const history = useHistory();
  const { claim_group_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [claimGroup, setClaimGroup] = useState(null);
  const [projects, setProjects] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const user = useSelector((state) => state.auth.user);
  const [params, setParams] = useState(
    QueryString.parse(location.search || initialParams)
  );
  const { t } = useTranslation();

  useEffect(() => {
    const fetchProjects = async () => {
      const claimGroup = await claimGroupService.get(claim_group_id);
      setClaimGroup(claimGroup);
      const projectsData = await claimProjectReportsService.all(claim_group_id, QueryString.stringify(params));
      const projects = projectsData.claim_project_reports;
      setProjects(projects);
      setTotalPages(projectsData.pages);
      history.push({
        search: QueryString.stringify(params),
      });
    };

    fetchProjects();
  }, [history, params])

  const handlePage = (pageNumber) => {
    setParams({ ...params, page: pageNumber });
  };

  const deleteProjectFn = useErrorHandling(useCallback(async (project) => {
    if (window.confirm('Are you sure want to delete project? All created tasks for the project will be deleted.')) {
      const params = {
        id: project.id,
        included_in_time_tracking: false
      };
      await claimProjectReportService.update(project.claim_group_id, params);
      handleToaster('Project was successfully deleted');
      window.location.reload();
    }
  }));

  const editProject = (project) => {
    history.push(`/${user.account_type}/time_tracking/claim_groups/${claim_group_id}/claim_project_reports/${project.id}`)
  };

  const items = [];
  for (let number = 1; number <= totalPages; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === parseInt(params.page)}
        onClick={() => handlePage(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  const headers = [
    { text: t('last_activity') },
    { text: t('project_name')},
    { text: t('business_unit')},
    { text: t('number_of_tasks')},
    { text: t('hours_spent')},
    { text: t('assignee')},
    { text: ''},
  ];


  if(!projects) return <Loading />

  const projectNamesList = projects.map(project => project.project_name);
  const projectBusinessUnitNames = (project) => {
    return (project.project_business_units?.map((bu, index) => {
      return(
        <>
          <Badge key={index} variant="primary">
            {bu.business_unit.name}
          </Badge>
          {' '}
        </>
      )
    }))
  }

  const rows = projects.map(project => {
    return {
      key: project.id,
      columns: [
        project.last_activity,
        <Link to={`/${user.account_type}/time_tracking/claim_groups/${claimGroup.id}/claim_project_reports/${project.id}/tasks`}>{project.project_name}</Link>,
        projectBusinessUnitNames(project),
        project.number_of_tasks,
        project.hours_spent,
        project.assigned_to,
        <>
          <EditButton onClick={() => { editProject(project) }} />
          <DeleteButton onClick={() => deleteProjectFn(project)} />
        </>
      ]
    }
  });

  return (
    <>
      <MasterSidebar accountType={accountType} currentPage='list_of_companies' />
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}/`}}>Home</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}/list_of_companies`}}>Companies</Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${user.account_type}/time_tracking/companies/${claimGroup.company_id}/claim_groups`}}>{claimGroup.company.name}</Breadcrumb.Item>
          <Breadcrumb.Item active>{claimGroup.name}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>

      <div className='text-right mb-2'>
        <Filters
          params={params}
          setParams={setParams}
          filters={[
            { name: 'project_name', label: t('project_name'), type: 'select', options: projectNamesList },
            { name: 'business_unit', label: t('business_unit'), type: 'select', options: [] }
          ]}
        />
      </div>
      <Card>
        <Table headers={headers}
               rows={rows}
        />
      </Card>

      <Pagination currentPage={Number(params.page)} totalPages={totalPages} handlePageChange={handlePage} /> 
    </>
  )
}

export default TimeTrackingProjectsPage