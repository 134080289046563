import ReportTemplateForm from '@components/admin/reportTemplates/ReportTemplateForm'
import { Loading } from '@rd-web-markets/shared/dist/util';
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import { reportTemplateInitial } from '@rd-web-markets/market/dist/reportTemplates';
import SidebarPortal from '@rd-web-markets/shared/dist/util/SidebarPortal';
import reportTemplateService from '@rd-web-markets/shared/dist/services/report_template.service';

const headerItemProp = {
  text: 'Companies',
  link: 'companies'
}

const CreateReportTemplatePage = ({handleToaster, accountType}) => {
  const [loading, setLoading] = useState(false);
  const [template, setTemplate] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const { search } = useLocation() //Required for Italy and UK
  const template_type = new URLSearchParams(search).get('template_type')

  useEffect(() => {
    setTemplate(reportTemplateInitial[template_type] || reportTemplateInitial) //Italy has technical
    return () => {
      !!reportTemplateInitial[template_type] &&
        reportTemplateInitial[template_type].report_template_categories.map(t => t.content = 'text here...');
    }
  }, [history, dispatch, template_type])
  const handleSubmit = async () => {
    setLoading(true);
    try {
      template.report_template_categories_attributes = template.report_template_categories;
      await reportTemplateService.create(template, template_type);
      handleToaster('Template created');
      setLoading(false);
      history.replace(`/${accountType}/report_templates/` + search);
    } catch (error) {
      dispatch(handleError(error));
    }
    setLoading(false);
  };

  const handleChange = (event) => {
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;
    setTemplate({
      ...template,
      [event.target.name]: value,
    });
  };

  const handleCategoryChange = (event, index) => {
    const templateCategoriesCopy = template.report_template_categories;
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;
    templateCategoriesCopy[index][event.target.name] = value;
    setTemplate({
      ...template,
      report_template_categories: templateCategoriesCopy
    });
  };

  const handleTinyMce = (content, index) => {
    const categoriesCopy = template.report_template_categories;
    categoriesCopy[index]['content'] = content;
    setTemplate({
      ...template,
      report_template_categories: categoriesCopy,
    });
  };

  const handleFinancialAnalysisMethodologyChange = (staffing_cost_methodology, consumables_methodology) => {
    setTemplate({
      ...template,
      staffing_cost_methodology,
      consumables_methodology
    });
  };

  const handleBack = () => {
    history.replace(`/${accountType}/report_templates/` + search);
  }

  if(!template) return <Loading />

  return (
    <>
      <SidebarPortal headerItem={headerItemProp} />
      <ReportTemplateForm
        selectedTemplate={template}
        handleFinancialAnalysisMethodologyChange={handleFinancialAnalysisMethodologyChange}
        handleBack={handleBack}
        handleSubmit={handleSubmit}
        loading={loading}
        handleChange={handleChange}
        handleTinyMce={handleTinyMce}
        handleCategoryChange={handleCategoryChange}
      />
    </>
  )
}

export default CreateReportTemplatePage
