import { useCallback, useEffect, useState } from 'react';
import { useErrorHandling } from '../hooks/useErrorHandling';
import { useLocation } from 'react-router-dom';
import generalRouteService from './general_route.service';
export function useGetFoldersAndFiles(targetModelId, type) {
  const [folders, setFolders] = useState([]);
  const [files, setFiles] = useState([]);
  const [currentFolderId, setCurrentFolderId] = useState(null);
  const [currentPath, setCurrentPath] = useState([]);
  const {
    search
  } = useLocation();
  const getFolderData = useErrorHandling(useCallback(async (folderToGet, type, targetModelId, setFolders, setFiles, setCurrentPath) => {
    let response;
    setFolders([]);
    if (folderToGet) {
      setFiles([]);
      response = await generalRouteService.get('folders', folderToGet);
      setFolders([...response.subfolders, ...response.project_virtual_folders, ...response.claim_group_virtual_folders]);
      setFiles([...response.folder_files, ...response.files_from_other_folders]);
      setCurrentPath([...response.folder_path]);
    } else {
      response = await generalRouteService.getAll('folders', "?type=".concat(type, "&id=").concat(targetModelId));
      setFolders([...response]);
      setCurrentPath([]);
    }
  }, []));
  const getFilesData = useErrorHandling(useCallback(async (setFiles, type, targetModelId) => {
    const response = await generalRouteService.getAll('folder_files', "?type=".concat(type, "&id=").concat(targetModelId));
    setFiles([...response]);
  }, []));
  useEffect(() => {
    const folderId = new URLSearchParams(search).get('folder');
    const key = new URLSearchParams(search).get('key');
    if (key !== 'documents') return;
    if (!folderId) {
      setCurrentFolderId(null);
      getFolderData(null, type, targetModelId, setFolders, setFiles, setCurrentPath);
      getFilesData(setFiles, type, targetModelId);
    } else {
      getFolderData(folderId, type, targetModelId, setFolders, setFiles, setCurrentPath);
    }
  }, [search, targetModelId]);
  return [setFolders, setFiles, setCurrentFolderId, currentFolderId, folders, files, currentPath];
}