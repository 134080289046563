import React, { useState, useEffect, useCallback } from 'react'
import { Card, Row, Col, Pagination, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as QueryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { Loading, Table } from '@rd-web-markets/shared/dist/util';
import { myTasksService } from '@services/task_management/myTasks.service';
import Filters from '@rd-web-markets/shared/dist/util/Filters';
import { DeleteButton, EditButton } from '@rd-web-markets/shared/dist/util/buttons';
import { trackingTasksService } from 'src/services/task_management/claim_project_reports/tracking_tasks.service';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import MasterSidebar from '@rd-web-markets/shared/dist/util/MasterSidebar';

const initialParams = 'page=1&query=';

const MyTasksPage = ({accountType}) => {
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const user = useSelector((state) => state.auth.user);
  const [params, setParams] = useState(
    QueryString.parse(location.search || initialParams)
  );
  const { t } = useTranslation();

  useEffect(() => {
    const fetchTasks = async () => {
      const tasks = await myTasksService.all(QueryString.stringify(params));
      setTasks(tasks);
      history.push({
        search: QueryString.stringify(params),
      });
    };

    fetchTasks();
  }, [history, params])

  const deleteTask = useErrorHandling(useCallback(async (task) => {
    if (window.confirm(t('are_you_sure_you_want_to_delete_this_task'))) {
      await trackingTasksService.delete(task.claim_project_report_id, task.id);
      const tasks = await myTasksService.all(QueryString.stringify(params));
      setTasks(tasks);
    }
  }, [params, t]));

  const handleSearch = (query) => {
    setParams({ ...params, query, page: 1 });
  };

  const handlePage = (pageNumber) => {
    window.scrollTo(0, 0);
    setParams({ ...params, page: pageNumber });
  };

  const items = [];
  for (let number = 1; number <= totalPages; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === parseInt(params.page)}
        onClick={() => handlePage(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  const headers = [
    { text: t('company') },
    { text: t('claim')},
    { text: t('project')},
    { text: t('type')},
    { text: t('title')},
    { text: t('status')},
    { text: t('tags')},
    '',
  ];

  const renderTags = tags => {
    if (tags.length > 3) {
      return (
        <OverlayTrigger
          key={'top'}
          placement={'top'}
          overlay={
            <Tooltip>
              {tags.map(tag => tag.name).join(', ')}
            </Tooltip>
          }
        >
          <div>{tags.length} tags</div>
        </OverlayTrigger>
      );
    } else {
      return tags.map(tag => tag.name).join(', ');
    }
  }

  if (!tasks) return <Loading />

  const rows = tasks.map(task => {
    return {
      key: task.id,
      columns: [
        task.claim_project_report.claim_group.company.name,
        task.claim_project_report.claim_group.name,
        task.claim_project_report.project_name,
        t(task.classification),
        task.title,
        t(task.completion_status),
        renderTags(task.tracking_task_tags),
        <>
          <EditButton
            as={Link}
            to={`/${user.account_type}/time_tracking/my_tasks/claim_project_reports/${task.claim_project_report_id}/edit_task/${task.id}`}
          />
          <DeleteButton onClick={() => deleteTask(task)} />
        </>,
      ]
    }
  });

  return (
    <>
      <MasterSidebar accountType={accountType} currentPage='time_tracking/my_tasks' />

      <div className='text-right mb-2'>
        <Filters
          params={params}
          setParams={setParams}
          filters={[
            { name: 'company', label: t('company'), type: 'text' },
            { name: 'claim', label: t('claim'), type: 'text' },
            { name: 'project', label: t('project'), type: 'text' },
            { name: 'type', label: t('type'), type: 'select', options: ['task', 'file_request']},
            { name: 'title', label: t('title'), type: 'text' },
            { name: 'status', label: t('status'), type: 'select', options: ['not_started', 'in_progress', 'done']}
          ]}
        />
      </div>

      <Card>
        <Table
          headers={headers}
          rows={rows}
        />
      </Card>

      <Pagination className='justify-content-end'>{items}</Pagination>
    </>
  )
}

export default MyTasksPage