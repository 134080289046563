import React, { useState } from 'react'
import { OverlayTrigger } from 'react-bootstrap'
import ButtonControls from '@rd-web-markets/shared/dist/technical_review/ButtonControls'
import CreateClaimChangeSetPopover from './CreateClaimChangeSetPopover'

const CostManagerReviewControls = ({
  reviewedObject,
  reviewedObjectClass,
  loading,
  company,
  claimGroup,
  createChangeSet,
  finalizeChangeSet,
  approveChangeSet,
  rejectChangeSet,
  createChangeSetsForAllClaimsInClaimGroup
}) => {
  const [showPopover, setShowPopover] = useState(false)

  const popover = props => 
    <CreateClaimChangeSetPopover
      {...props}

      onAllSelected={async () => {
        setShowPopover(false)
        await createChangeSetsForAllClaimsInClaimGroup()
      }}
      onSingleSelected={async () => {
        setShowPopover(false)
        await createChangeSet(reviewedObject, 'Claim')
      }}
    />

  return (
    <div style={{ width: 'fit-content', margin: '0 auto' }}>
      <OverlayTrigger className="pls" show={showPopover} placement="bottom" overlay={popover}>
        <ButtonControls
          claimGroup={claimGroup}
          reviewedObject={reviewedObject}
          reviewedObjectClass={reviewedObjectClass}
          loading={loading}
          company={company}
          createChangeSet={() => setShowPopover(!showPopover)}
          finalizeChangeSet={finalizeChangeSet}
          approveChangeSet={approveChangeSet}
          rejectChangeSet={rejectChangeSet}
          buttonName={'Set For Manager Cost Review'}
        />
      </OverlayTrigger>
    </div>
  );
}

export default CostManagerReviewControls