import React from 'react';
import { Breadcrumb} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import MasterSidebar from '@rd-web-markets/shared/dist/util/MasterSidebar';
import Rates from '@rd-web-markets/market/dist/rates/Rates'

export default function RatesPage () {
  return (
    <>
      <MasterSidebar accountType='admin' currentPage='rates' />
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{to: '/admin'}}>Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Rates</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>

      <Rates />
    </>
  );
}
